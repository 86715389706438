// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import $ from 'jquery'
import '../css/public.scss'

import TrackEvents from './shared/tracks'
import Notifications from './modules/notifications'
import { setCookie } from './shared/cookies'

const VISITOR_FIELD = '#user_visitor_id'

function fixHeader() {
  const body = document.getElementById('body')
  const scrollBarPosition = window.scrollY || document.documentElement.scrollTop

  if (!body) return

  body.classList.toggle('fixed', scrollBarPosition > 300)
}

function handleComagic() {
  try {
    // eslint-disable-next-line no-undef
    if (typeof Comagic !== 'undefined' && typeof Comagic.getSessionId === 'function') {
      // eslint-disable-next-line no-undef
      const visitor_id = Comagic.getSessionId()

      if (visitor_id) {
        setCookie('visitor_id', visitor_id, 7)

        const visitorField = $(VISITOR_FIELD)

        if (visitorField.length === 0 || visitorField.val()) return

        visitorField.val(visitor_id)
      }
    }
  } catch (error) {
    new Error('Comagic issue:', error)
  }
}

window.addEventListener('DOMContentLoaded', () => {
  handleComagic()

  const body = document.getElementById('body')
  if (body) {
    document.addEventListener('scroll', fixHeader, { passive: true })
  }
})

window.togglePassword = function () {
  const icon = document.getElementById('passwordToggle')
  const field = document.getElementById('user_password')

  if (icon && field) {
    icon.classList.toggle('open')
    field.type = field.type === 'password' ? 'text' : 'password'
  }
}

const observer = new MutationObserver(() => {
  if (typeof Comagic !== 'undefined') {
    observer.disconnect()
    handleComagic()
  }
})

observer.observe(document.documentElement, { childList: true, subtree: true })

new TrackEvents()
new Notifications()
